import React from 'react';
import styled from 'styled-components';

import Bg from './../../../img/services/bg-stats.jpg';

const Wrapper = styled.div`
  /* background-image: url('${Bg}'); */
  background-size: cover;

  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 80px;
  padding-top: 80px;
  /* margin-bottom: -32px; */

  .inner {
    max-width: 1004px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;

    h2 {
      font-family: Oswald;
      font-style: normal;
      font-weight: 200;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      margin-top: 0;
    }
    .sub-text {
      max-width: 630px;
      text-align: center;

      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
    }

    .item {
      width: 230px;
      /* height: 155px; */
      /* margin-right: 28px; */
      margin-bottom: 32px;
      /* &:nth-of-type(4n) {
        margin-right: 0px;
      } */

      .icon {
        margin: 0 auto;
        height: 90px;
        width: auto;
        display: block;
        margin-bottom: 15px;
      }
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }
    &.per-row-3 {
      max-width: 800px;
    }
    @media (max-width: 1085px) {
      max-width: 800px;
      .item:nth-child(4n) {
        /* margin-right: 24px; */
      }
      .item:nth-child(3n) {
        /* margin-right: 0px; */
      }
    }
    @media (max-width: 775px) {
      max-width: 600px;
    }
    @media (max-width: 545px) {
      .item {
        max-width: 200px;
        width: 50%;
      }
    }
  }

  &.dark-text .inner {
    h1 {
      color: #585858;
    }
    .sub-text {
      color: #585858;
    }
    .item {
      .label {
        color: #585858;
      }
    }
  }
`;

function list(props) {
  let bgImage = `url('${Bg}')`;
  if (props.bgImage) {
    bgImage = props.bgImage;
  } else if (props.bgImage === false) {
    bgImage = 'none';
  }

  return (
    <Wrapper style={{ backgroundImage: bgImage }} className={` ${props.darkText ? 'dark-text' : ''}`}>
      <div className={`inner`} style={{ width: '100%', maxWidth: '100%' }}>
        <h2>{props.title}</h2>
        <div className="sub-text">{props.desc ? props.desc : ''}</div>
      </div>
      <div style={{ height: '45px' }}></div>
      <div className={`inner per-row-${props.perRow ? props.perRow : '4'}`}>
        {props.data.map((row, index) => (
          <div className="item" key={index}>
            <img className="icon" src={row.icon.publicURL} />
            <div className="label">{row.label}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

export default list;
